import FreestyleComponent from '@wix/thunderbolt-elements/src/components/Freestyle/viewer/Freestyle';
import FreestyleController from '@wix/thunderbolt-elements/src/components/Freestyle/viewer/Freestyle.controller';


const Freestyle = {
  component: FreestyleComponent,
  controller: FreestyleController
};


export const components = {
  ['Freestyle']: Freestyle
};

